export function formatDateTime(datetime?: string | null) {
  if (datetime == null) return '---';
  const date = new Date(datetime);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = `${date.getHours()}`.padStart(2, '0');
  const minutes = `${date.getMinutes()}`.padStart(2, '0');
  const seconds = `${date.getSeconds()}`.padStart(2, '0');
  return `${month}/${day} ${hours}:${minutes}:${seconds}`;
}
