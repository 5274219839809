import { useEffect, useCallback, useState } from "react";
import axios, { AxiosError } from 'axios';

const instance = axios.create({
  baseURL: '/',
  // baseURL: 'http://192.168.1.203:3000',
});

export const GROUP_OPTIONS = [
  {
    label: 'None',
    value: '',
  },
  {
    label: 'A',
    value: 'A',
  },
  {
    label: 'B',
    value: 'B',
  },
]

export type User = {
  id: string;
  name: string;
  group: string | null;
}

export type Setting = {
  maxBleNum: number;
  totalSpan: number;
  lowerLimit: number;
  users: User[];
}

export function useSetting({ onSuccess }: {
  onSuccess?: () => void;
}) {
  const [setting, setSetting] = useState<Setting | null>(null);

  const getSetting = useCallback(async () => {
    const { data } = await instance.get<Setting>("/setting");
    setSetting(data);
  }, []);

  const updateSetting = useCallback(async (setting: Setting, password: string) => {
    try {
      const { users, ...rest } = setting;
      const { data } = await instance.put<Setting>("/setting", { ...rest, password });
      setSetting(data);
      alert("Updated setting");
      onSuccess?.();
    } catch (e) {
      if (e instanceof AxiosError && e.response?.status === 401) {
        alert("Incorrect password");
      } else {
        alert("Failed to update setting");
      }
    }
  }, []);

  const updateUsers = useCallback(async (setting: Setting, password: string) => {
    try {
      const { users: _users } = setting;
      const users = _users.filter((u) => u.id !== "");
      const { data } = await instance.put<Setting>("/setting/users", { users, password });
      setSetting(data);
      alert("Updated setting");
      onSuccess?.();
    } catch (e) {
      if (e instanceof AxiosError && e.response?.status === 401) {
        alert("Incorrect password");
      } else {
        alert("Failed to update setting");
      }
    }
  }, []);

  useEffect(() => {
    getSetting()
  }, [])

  return { setting, updateSetting, updateUsers };
}
