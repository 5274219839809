import { useEffect, useCallback, useState } from "react";
import axios from 'axios';

const instance = axios.create({
  baseURL: '/',
});

export type Summary = {
  id: number;
  startTime: string;
  stopTime: string;
}

export function useSummary() {
  const [summaryList, setSummaryList] = useState<Summary[]>([]);
  const [loading, setLoading] = useState(false);

  const auth = useCallback(async (password: string) => {
    try {
      const { data } = await instance.post<{ token: string }>("/summary/auth", { password });
      return data.token;
    } catch (e) {
      alert("Incorrect password");
      return null;
    }
  }, []);

  const getSummary = useCallback(async () => {
    const { data } = await instance.get<Summary[]>("/summary");
    setSummaryList(data);
  }, []);

  const start = useCallback(async (token: string) => {
    try {
      setLoading(true);
      await instance.post("/summary", { action: "start", token });
      await getSummary();
    } catch (e) {
      alert("Failed to start");
    } finally {
      setLoading(false);
    }
  }, [getSummary]);

  const stop = useCallback(async (token: string) => {
    try {
      setLoading(true);
      await instance.post("/summary", { action: "stop", token });
      await getSummary();
    } catch (e) {
      alert("Failed to stop");
    } finally {
      setLoading(false);
    }
  }, [getSummary]);

  const broadcast = useCallback(async (token: string) => {
    try {
      setLoading(true);
      await instance.post("/summary", { action: "broadcast", token });
      await new Promise(resolve => setTimeout(resolve, 1000));
    } catch (e) {
      alert("Failed to broadcast");
    } finally {
      setLoading(false);
    }
  }, []);

  const reset = useCallback(async (token: string) => {
    if (!window.confirm("Are you sure to reset?")) {
      return;
    }
    try {
      setLoading(true);
      await instance.post("/summary", { action: "reset", token });
      await getSummary();
    } catch (e) {
      alert("Failed to reset");
    } finally {
      setLoading(false);
    }
  }, [getSummary]);

  useEffect(() => {
    getSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { auth, summaryList, start, stop, broadcast, reset, loading };
}
