import React, { useEffect } from 'react';
import { Button, Box, TextField } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';

import UsersTable from './UsersTable';
import { Setting } from './hooks/useSetting';

type Props = {
  edit?: boolean;
  setting: Setting;
  onChangeSetting: (setting: Setting, password: string) => void;
  onEdit: () => void;
  onCancel: () => void;
};

const UsersContainer: React.FC<Props> = ({
  edit,
  setting,
  onChangeSetting,
  onEdit,
  onCancel,
}) => {
  const methods = useForm<Setting & { password: string }>({
    defaultValues: setting,
  });

  const onSubmit = (data: Setting & { password: string }) => {
    const { password, ...rest } = data;
    onChangeSetting(rest, password);
  };

  useEffect(() => {
    methods.reset(setting);
  }, [edit]);

  return (
    <FormProvider {...methods}>
      <UsersTable edit={edit} />
      {edit ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            marginTop: 2,
          }}
        >
          <TextField
            label="password"
            size="small"
            type="password"
            fullWidth
            {...methods.register('password')}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
            }}
          >
            <Button size="large" color="error" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              size="large"
              variant="contained"
              onClick={methods.handleSubmit(onSubmit)}
            >
              SAVE USERS
            </Button>
          </Box>
        </Box>
      ) : (
        <Button
          sx={{
            marginTop: 2,
          }}
          size="large"
          variant="contained"
          onClick={onEdit}
        >
          Edit
        </Button>
      )}
    </FormProvider>
  );
};

export default UsersContainer;
