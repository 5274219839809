import React from 'react';
import {
  Grid,
  InputBase,
  Select,
  MenuItem,
  Button,
  IconButton,
} from '@mui/material';
import { useFormContext, useFieldArray, Controller } from 'react-hook-form';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import { Setting, GROUP_OPTIONS } from './hooks/useSetting';

type Props = {
  edit?: boolean;
};

const UsersTable: React.FC<Props> = ({ edit = false }) => {
  const { control, register } = useFormContext<
    Setting & { password: string }
  >();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'users',
  });

  const onClickAdd = () => {
    append({
      id: '',
      name: '',
      group: null,
    });
  };
  const onClickRemove = (index: number) => {
    remove(index);
  };

  return (
    <Grid
      container
      sx={{
        border: '1px solid white',
        borderBottom: 'none',
      }}
    >
      {fields.map((user, index) => (
        <React.Fragment key={user.id}>
          <Grid
            xs={2}
            sx={{
              borderBottom: '1px solid white',
            }}
          >
            <InputBase
              placeholder="ID"
              sx={{
                width: '100%',
                height: '100%',
                padding: '0 8px',
              }}
              inputProps={{
                maxLength: 2,
              }}
              readOnly={!edit}
              {...register(`users.${index}.id`)}
            />
          </Grid>
          <Grid
            xs={8}
            sx={{
              borderLeft: '1px solid white',
              borderBottom: '1px solid white',
            }}
          >
            <InputBase
              placeholder="Name"
              sx={{
                width: '100%',
                height: '100%',
                padding: '0 8px',
              }}
              readOnly={!edit}
              {...register(`users.${index}.name`)}
            />
          </Grid>
          <Grid
            xs={2}
            sx={{
              position: 'relative',
              borderLeft: '1px solid white',
              borderBottom: '1px solid white',
            }}
          >
            <Controller
              name={`users.${index}.group`}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label="group"
                  placeholder="Group"
                  size="small"
                  readOnly={!edit}
                  sx={{
                    width: '100%',
                    border: 'none',
                  }}
                >
                  {GROUP_OPTIONS.map((group) => (
                    <MenuItem key={group.value} value={group.value}>
                      {group.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {edit && (
              <IconButton
                tabIndex={-1}
                color="error"
                sx={{
                  position: 'absolute',
                  left: '100%',
                }}
                onClick={() => onClickRemove(index)}
              >
                <RemoveCircleIcon />
              </IconButton>
            )}
          </Grid>
        </React.Fragment>
      ))}
      {edit && (
        <Grid
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderBottom: '1px solid white',
          }}
        >
          <Button
            onClick={onClickAdd}
            sx={{
              width: '100%',
            }}
            startIcon={<AddCircleIcon />}
          >
            Add User
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default UsersTable;
