import React, { useMemo, useEffect } from 'react';
import { Typography, Stack, TextField, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';

import { formatDateTime } from './utils/formatDateTime';
import { useSummary, Summary } from './hooks/useSummary';

type Props = {
  token: string;
};

const ControlPanel: React.FC<Props> = ({ token }) => {
  const { summaryList, loading, start, stop, broadcast, reset } = useSummary();

  const isTotalling = useMemo(() => {
    return summaryList.some((summary) => !summary.stopTime);
  }, [summaryList]);

  return (
    <Stack
      direction="column"
      alignItems="center"
      gap={4}
      sx={{
        width: '100%',
      }}
    >
      <Stack direction="column" gap={2}>
        {summaryList.map((summary) => (
          <SummaryTimeRange key={summary.id} summary={summary} />
        ))}
      </Stack>
      {isTotalling && (
        <Counter latestSummary={summaryList[summaryList.length - 1]} />
      )}
      <Stack
        direction="column"
        gap={4}
        sx={{
          width: 240,
        }}
      >
        {!isTotalling ? (
          <Button
            variant="contained"
            size="large"
            onClick={() => start(token)}
            disabled={loading}
            fullWidth
          >
            Start
          </Button>
        ) : (
          <Button
            variant="contained"
            size="large"
            color="warning"
            onClick={() => stop(token)}
            disabled={loading}
            fullWidth
          >
            Stop
          </Button>
        )}
        <Button
          variant="contained"
          size="large"
          color="success"
          onClick={() => broadcast(token)}
          disabled={loading}
          fullWidth
        >
          Broadcast
        </Button>
        <Button
          variant="contained"
          size="large"
          color="error"
          onClick={() => reset(token)}
          disabled={loading}
          fullWidth
        >
          Reset
        </Button>
      </Stack>
    </Stack>
  );
};

const SummaryTimeRange: React.FC<{ summary: Summary }> = ({ summary }) => {
  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <AccessAlarmIcon
        fontSize="large"
        color={summary.stopTime ? 'inherit' : 'primary'}
      />
      <Typography fontSize={18} fontWeight={700}>
        {formatDateTime(summary.startTime)}
      </Typography>
      <Typography fontSize={18} fontWeight={700}>
        ~
      </Typography>
      <Typography fontSize={18} fontWeight={700}>
        {formatDateTime(summary.stopTime)}
      </Typography>
    </Stack>
  );
};

const Counter: React.FC<{ latestSummary: Summary }> = ({ latestSummary }) => {
  const [count, setCount] = React.useState(
    Math.round(
      (new Date().getTime() - new Date(latestSummary.startTime).getTime()) /
        1000
    )
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prev) => prev + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Typography fontSize={32} fontWeight={700}>
      {new Date(count * 1000).toISOString().substr(11, 8)}
    </Typography>
  );
};

export const PasswordInput: React.FC<{
  onSuccess: (token: string) => void;
}> = ({ onSuccess }) => {
  const { register, handleSubmit } = useForm<{ password: string }>();
  const { auth } = useSummary();

  const onClickAuth = async (data: { password: string }) => {
    const password = data.password;
    const token = await auth(password);
    if (token) {
      onSuccess(token);
    }
  };

  return (
    <Stack
      component="form"
      onSubmit={handleSubmit(onClickAuth)}
      direction="column"
      gap={2}
      alignItems="center"
      sx={{
        minWidth: 240,
      }}
    >
      <TextField
        label="password"
        type="password"
        fullWidth
        {...register('password')}
      />
      <Button
        variant="contained"
        type="submit"
        sx={{
          width: 'max-content',
        }}
      >
        Authenticate
      </Button>
    </Stack>
  );
};

export default ControlPanel;
