import React, { useState } from 'react';
import {
  Stack,
  Typography,
  Paper,
  Modal,
  IconButton,
  TextField,
  Button,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { useForm } from 'react-hook-form';

import { Setting } from './hooks/useSetting';

type Props = {
  setting: Setting;
  onChangeSetting: (setting: Setting, password: string) => void;
};

const SettingModal: React.FC<Props> = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <IconButton
        onClick={() => setOpen(true)}
        sx={{
          position: 'absolute',
          top: 8,
          right: 24,
        }}
      >
        <SettingsIcon />
      </IconButton>
      <Modal open={open} onClose={() => setOpen(false)}>
        <SettingModalContent {...props} />
      </Modal>
    </>
  );
};

const SettingModalContent: React.FC<Props> = ({ setting, onChangeSetting }) => {
  const { register, handleSubmit } = useForm<Setting & { password: string }>({
    defaultValues: setting,
  });

  const onClickSave = (data: Setting & { password: string }) => {
    const { password, ...rest } = data;
    onChangeSetting(rest, password);
  };

  return (
    <Paper
      component="form"
      onSubmit={handleSubmit(onClickSave)}
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90vw',
        maxWidth: 400,
        padding: '24px',
      }}
    >
      <Typography variant="h5" fontWeight={700} sx={{ marginBottom: 2 }}>
        Settings
      </Typography>
      <Stack direction="column" gap={2}>
        <TextField
          label="Max BLE Number"
          type="number"
          size="small"
          fullWidth
          {...register('maxBleNum', { valueAsNumber: true })}
        />
        <TextField
          label="TouchDesigner Summary Span (sec)"
          type="number"
          size="small"
          fullWidth
          {...register('totalSpan', { valueAsNumber: true })}
        />
        <TextField
          label="TouchDesigner Lower Limit (0~1)"
          size="small"
          fullWidth
          {...register('lowerLimit', { valueAsNumber: true })}
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        gap={2}
        sx={{
          marginTop: '48px',
        }}
      >
        <TextField
          label="password"
          size="small"
          type="password"
          fullWidth
          {...register('password')}
        />
        <Button variant="contained" type="submit">
          SAVE
        </Button>
      </Stack>
    </Paper>
  );
};

export default SettingModal;
