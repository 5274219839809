import React, { useState } from 'react';
import { Container, Box, CircularProgress, Tabs, Tab } from '@mui/material';

import SettingModal from './SettingModal';
import UsersContainer from './UsersContainer';
import ControlPanel, { PasswordInput } from './ControlPanel';

import { useSetting } from './hooks/useSetting';

const App: React.FC = () => {
  // const[socket] = useState(io('/'))
  // const[socket] = useState(io('http://192.168.1.203:81'))

  const [edit, setEdit] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [token, setToken] = useState<string | null>(null);
  const { setting, updateSetting, updateUsers } = useSetting({
    onSuccess: () => {
      setEdit(false);
    },
  });

  return (
    <Box
      sx={{
        background: (theme) => theme.palette.background.default,
        width: '100%',
        height: '100vh',
      }}
    >
      {setting && (
        <SettingModal setting={setting} onChangeSetting={updateSetting} />
      )}
      <Container
        maxWidth="sm"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: 8,
        }}
      >
        <Tabs
          value={tabIndex}
          onChange={(_, v) => setTabIndex(v)}
          centered
          sx={{
            marginBottom: 4,
          }}
        >
          <Tab label="User List" />
          <Tab label="Control" />
        </Tabs>
        {tabIndex === 0 && (
          <>
            {setting ? (
              <UsersContainer
                edit={edit}
                setting={setting}
                onChangeSetting={updateUsers}
                onEdit={() => setEdit(true)}
                onCancel={() => setEdit(false)}
              />
            ) : (
              <CircularProgress size={56} />
            )}
          </>
        )}
        {tabIndex === 1 && (
          <>
            {token ? (
              <ControlPanel token={token} />
            ) : (
              <PasswordInput onSuccess={setToken} />
            )}
          </>
        )}
      </Container>
    </Box>
  );
};

export default App;
